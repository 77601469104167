<template>
  <b-card no-body>

    <b-card-header>
      <div>
        <b-card-title class="mb-1">
          Statistics
        </b-card-title>
        <b-card-sub-title>Commercial networks and enterprises</b-card-sub-title>
      </div>
      <b-card-text class="font-medium-5 mb-0">
        <feather-icon
        v-b-popover.hover.right="'Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.'"
        title="Popover on right"
          icon="HelpCircleIcon"
          size="21"
          class="text-muted cursor-pointer"
        />
      </b-card-text>
    </b-card-header>

    <!-- chart -->
    <b-card-body>
        <Highcharts :options="highchartsOptions" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardSubTitle, BCardTitle, VBPopover, BCardText
} from 'bootstrap-vue'


import Highcharts from "highcharts";
import loadMap from "highcharts/modules/map.js";
import { genComponent } from "vue-highcharts";

loadMap(Highcharts);

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    BCardText,

   Highcharts: genComponent("Highcharts", Highcharts),
    Highmaps: genComponent("Highmaps", Highcharts),
    // Highstock: genComponent('Highstock', Highcharts),
    // HighchartsGantt: genComponent('HighchartsGantt', Highcharts),

  },

 directives: {
    'b-popover': VBPopover,
  },
  data() {
    return {
      highchartsOptions: {
        chart: {
          zoomType: "x",
          panning: true,
          panKey: "shift",
        },

        boost: {
          useGPUTranslations: true,
        },

        title: false,

        /*
        title: {
          text: "Highcharts drawing 100 points",
        },

        subtitle: {
          text: "Using the Boost module",
        },
*/
        tooltip: {
          valueDecimals: 2,
        },

        series: [
          {
            data: [
              [0, 13.443335679763775],
              [1, 3.6526085515627704],
              [2, 3.590634549040961],
              [3, 4.45158274180653],
              [4, 3.86582087383639],
              [5, 4.3983135554466815],
              [6, 3.6793183541358383],
              [7, 4.451589831640511],
              [8, 3.9676825289070554],
              [9, 3.79780652775253],
              [10, 3.7539267452210967],
              [11, 3.804913661662347],
              [12, 4.08287747348681],
              [13, 4.3811530641031995],
              [14, 4.522323573446862],
              [15, 4.2316414599864665],
              [16, 3.9335577529810912],
              [17, 4.004290944992436],
              [18, 4.183598983667087],
              [19, 4.644426907857698],
              [20, 3.888988208648671],
              [21, 4.574443210930573],
              [22, 4.709253748208285],
              [23, 3.875857978529738],
              [24, 4.6406573522013845],
              [25, 4.5631139809921235],
              [26, 4.8203423750513625],
              [27, 4.053194996674797],
              [28, 4.0976054606125025],
              [29, 4.046836036344133],
              [30, 4.561126191761524],
              [31, 4.162663334593371],
              [32, 4.231836595425736],
              [33, 4.506728059803285],
              [34, 4.267367613775786],
              [35, 4.597972797381602],
              [36, 4.752121869614341],
              [37, 5.091959722981362],
              [38, 4.402623646419016],
              [39, 4.641405770357744],
              [40, 4.677763525047597],
              [41, 4.519223658184228],
              [42, 4.896404326331309],
              [43, 4.551152210186885],
              [44, 5.032394437923237],
              [45, 5.021217776055705],
              [46, 4.39297204016521],
              [47, 4.973872037403339],
              [48, 4.376839563561767],
              [49, 4.855889044352799],
              [50, 5.00793222661455],
              [51, 5.113379503920946],
              [52, 4.910730659216744],
              [53, 4.5045550586989735],
              [54, 5.058259988722863],
              [55, 4.5757454394037875],
              [56, 4.91793050760589],
              [57, 5.494662246118797],
              [58, 5.237964130485253],
              [59, 5.333695803228357],
              [60, 5.417631790426709],
              [61, 5.025335889071884],
              [62, 5.054350959813995],
              [63, 5.073214054980517],
              [64, 5.328998484196691],
              [65, 4.804599836222346],
              [66, 4.873730799170932],
              [67, 5.165887392838744],
              [68, 5.412399303418296],
              [69, 5.486141853986167],
              [70, 5.131607422924606],
              [71, 4.846803471621855],
              [72, 5.232878423518468],
              [73, 5.603365697472077],
              [74, 5.604689844509991],
              [75, 5.498320355122859],
              [76, 5.349307119855663],
              [77, 5.350979523420175],
              [78, 5.7360904624089555],
              [79, 5.580225687288714],
              [80, 5.2070568591746795],
              [81, 5.605025817549901],
              [82, 5.2086720354684015],
              [83, 5.300586726460687],
              [84, 5.762899044782051],
              [85, 5.1509445726710466],
              [86, 5.017782451431186],
              [87, 5.04548949823111],
              [88, 5.505894525637117],
              [89, 5.753797480133448],
              [90, 5.7140774022526495],
              [91, 5.72214045846631],
              [92, 5.310621645280324],
              [93, 5.229684393333187],
              [94, 6.017516002846634],
              [95, 5.181534794430606],
              [96, 5.883754445656352],
              [97, 5.444223170156578],
              [98, 5.602602442247174],
              [99, 5.656508274917975],
              [100, 6.310104572317335],
              [101, 6.7745854630328015],
              [102, 6.715553299716035],
              [103, 6.966284273572889],
              [104, 6.960833230959645],
              [105, 6.615956546912299],
              [106, 7.122240594604162],
              [107, 6.4611766190650455],
              [108, 6.449448466930175],
              [109, 6.5094547562002765],
              [110, 7.014979943185388],
              [111, 7.1435002705769275],
              [112, 6.4569089888125255],
              [113, 6.795973873765581],
              [114, 6.871723932135829],
              [115, 7.248262820278541],
              [116, 6.575021592299058],
              [117, 6.914825872247135],
              [118, 6.485427206351616],
              [119, 6.62010019715621],
              [120, 7.090598522790695],
              [121, 7.172380192164165],
              [122, 7.28874426435577],
              [123, 7.07942209172324],
              [124, 6.70259905025872],
              [125, 6.693033983685325],
              [126, 6.622770596749013],
              [127, 6.934433301466806],
              [128, 6.459536502607031],
              [129, 6.486828541601759],
              [130, 6.471400940852556],
              [131, 7.3124574799507185],
              [132, 6.842963314176451],
              [133, 7.357302343698964],
              [134, 6.966342127166628],
              [135, 7.231436493957803],
              [136, 6.6799964265366185],
              [137, 6.797075503963602],
              [138, 7.385526938506678],
              [139, 7.085532198593484],
              [140, 6.751216986118967],
              [141, 6.532700095501317],
              [142, 6.872071884298148],
              [143, 7.370152161007033],
              [144, 7.3955884058578665],
              [145, 6.53856254526141],
              [146, 6.8609779506050455],
              [147, 6.732660380022943],
              [148, 6.571199516106788],
              [149, 6.594459612143417],
              [150, 7.384221619318961],
              [151, 6.998822891054528],
              [152, 7.286479271319438],
              [153, 6.4842688490241915],
              [154, 7.204327304457985],
              [155, 6.875964712159407],
              [156, 6.507938223547901],
              [157, 7.10845628283521],
              [158, 7.129598884708419],
              [159, 6.6502143792491415],
              [160, 7.268926661386793],
              [161, 7.053435353410127],
              [162, 6.741184122937357],
              [163, 6.557199294276627],
              [164, 7.066826026269286],
              [165, 7.435503145050349],
              [166, 7.295123832151807],
              [167, 6.884015252191073],
              [168, 6.503771537746412],
              [169, 6.642946374627903],
              [170, 6.859342200024214],
              [171, 6.824971285500999],
              [172, 7.209570145535595],
              [173, 7.372579067426602],
              [174, 7.168580400865034],
              [175, 6.802646725053563],
              [176, 6.577714547614024],
              [177, 6.8447856482900775],
              [178, 6.713232587917814],
              [179, 6.540790853572977],
              [180, 6.636121054288483],
              [181, 7.058013569311249],
              [182, 6.448820302166686],
              [183, 7.2078423670609135],
              [184, 6.560717067219789],
              [185, 6.908639546579886],
              [186, 7.005357689832915],
              [187, 6.528754055359019],
              [188, 7.1425268062814995],
              [189, 6.664389099760959],
              [190, 6.54533201065165],
              [191, 7.1490574552334145],
              [192, 6.576499536702237],
              [193, 7.097408605872735],
              [194, 6.443408991675612],
              [195, 7.120442588831907],
              [196, 6.408485067642304],
              [197, 7.040971188116648],
              [198, 6.897298428816582],
              [199, 6.280694626844546],
              [200, 5.871166912685689],
              [201, 5.573957904613357],
              [202, 5.13415790521028],
              [203, 5.345324560928735],
              [204, 5.766921168077726],
              [205, 5.93161901715091],
              [206, 6.006167046912239],
              [207, 5.5774621735615195],
              [208, 5.140932370371691],
              [209, 5.1065402708635235],
              [210, 5.110729638026451],
              [211, 5.954296185700676],
              [212, 5.228821513160914],
              [213, 5.809323402938142],
              [214, 5.946591272538038],
              [215, 5.661478736599938],
              [216, 5.186130023086766],
              [217, 5.279085098424273],
              [218, 5.444755964719254],
              [219, 5.707511852415353],
              [220, 5.856633780366788],
              [221, 5.224421381978859],
              [222, 5.581764758205158],
              [223, 5.527632139131592],
              [224, 5.674648751950628],
              [225, 5.095308870465135],
              [226, 4.834216131995217],
              [227, 5.292900803138862],
              [228, 5.276214480923118],
              [229, 5.606042723624003],
              [230, 5.384779578886568],
              [231, 5.218330602478927],
              [232, 4.990517779782817],
              [233, 5.383518208363979],
              [234, 5.014363918488981],
              [235, 5.242559785752636],
              [236, 5.356410315767717],
              [237, 5.6624092480449395],
              [238, 5.452130567818997],
              [239, 5.278781683110637],
              [240, 5.442748036868047],
              [241, 5.336723365887199],
              [242, 4.806115535078828],
              [243, 5.092654844212835],
              [244, 5.266802169285676],
              [245, 4.984364538814376],
              [246, 4.797372972505073],
              [247, 5.424824224427904],
              [248, 4.829763127999252],
              [249, 4.631284210339297],
              [250, 4.4934822933761565],
              [251, 4.528198122353694],
              [252, 5.367538384473723],
              [253, 5.338369918828082],
              [254, 4.661822504457225],
              [255, 5.332752566295199],
              [256, 5.265195661443737],
              [257, 4.604390078184757],
              [258, 4.975604281167665],
              [259, 4.361102961258926],
              [260, 4.854875846488744],
              [261, 5.2478642082926],
              [262, 5.073986092780125],
              [263, 4.721103602167338],
              [264, 4.285464766494965],
              [265, 4.881063895845574],
              [266, 4.319990521746787],
              [267, 4.55181022772427],
              [268, 4.433814889880097],
              [269, 5.078189378341168],
              [270, 4.825033190826871],
              [271, 4.308694279874924],
              [272, 4.692959314091192],
              [273, 4.898312561711541],
              [274, 4.086550613732755],
              [275, 4.130305792524367],
              [276, 4.357966044457877],
              [277, 4.085830490384904],
              [278, 4.535525806832881],
              [279, 4.309952596455272],
              [280, 4.120974535387782],
              [281, 4.735385496495316],
              [282, 4.2535676280143315],
              [283, 4.753138240975482],
              [284, 4.7088769332626494],
              [285, 4.072031543875422],
              [286, 4.456200136850751],
              [287, 3.9005783378988115],
              [288, 4.401593830699753],
              [289, 4.402460043227168],
              [290, 4.325522739100271],
              [291, 3.8099116557909483],
              [292, 4.168395341770956],
              [293, 4.234333149460011],
              [294, 4.607452303987544],
              [295, 4.3356703642277985],
              [296, 4.545000395419863],
              [297, 4.002823331546609],
              [298, 3.954601036320084],
              [299, 3.7051870144141397],
              [300, 4.712593971443038],
              [301, 4.4476599361018545],
              [302, 5.067558992942786],
              [303, 4.493489156279152],
              [304, 4.610280169758099],
              [305, 4.389573229303121],
              [306, 4.928926569471937],
              [307, 5.183863505307739],
              [308, 4.327887686875334],
              [309, 4.622781703429439],
              [310, 5.033784281741925],
              [311, 4.23444379744488],
              [312, 4.896502650883223],
              [313, 4.344392863136176],
              [314, 4.913714566745501],
              [315, 4.941281511458961],
              [316, 4.4990802347413545],
              [317, 4.703390350664643],
              [318, 4.178961851094098],
              [319, 4.283074764050288],
              [320, 4.730569579391922],
              [321, 4.14576263340895],
              [322, 4.610597313037067],
              [323, 4.318479408534137],
              [324, 4.684110641105302],
              [325, 4.3966166770236015],
              [326, 4.449576469589873],
              [327, 4.565486911062492],
              [328, 4.766940765751572],
              [329, 4.116224825212352],
              [330, 4.64538806194075],
              [331, 4.513543023258705],
              [332, 4.618279350782075],
              [333, 4.424546403081592],
              [334, 3.779239614217932],
              [335, 3.682688159663032],
              [336, 4.376093126535805],
              [337, 4.444302029327215],
              [338, 4.155456288508385],
              [339, 4.216712950534857],
              [340, 4.310557614213394],
              [341, 3.8735563836359725],
              [342, 3.781604720467106],
              [343, 3.907531098658639],
              [344, 3.874170525043497],
              [345, 4.023341393231897],
              [346, 3.799907909249918],
              [347, 4.349093743064521],
              [348, 4.2418956700325126],
              [349, 3.5279719707996433],
              [350, 3.769463585781911],
              [351, 4.355627065603731],
              [352, 3.407523818508381],
              [353, 3.4278178812776243],
              [354, 3.579155771307807],
              [355, 4.094728442603536],
              [356, 3.6007499838531967],
              [357, 4.019495648322838],
              [358, 4.03007340680429],
              [359, 4.077573699069548],
              [360, 3.49025917966965],
              [361, 3.574823056976687],
              [362, 3.2174968150615966],
              [363, 3.7797831997525444],
              [364, 3.831083956100451],
              [365, 3.911806569333936],
              [366, 3.4228507895041],
              [367, 3.866954832498433],
              [368, 3.368813295935446],
              [369, 3.8886057516056347],
              [370, 3.7007196603107415],
              [371, 3.4457916827987214],
              [372, 3.78384960625306],
              [373, 3.710315530340316],
              [374, 3.6925770116908074],
              [375, 3.0997450642762834],
              [376, 3.4803153190029725],
              [377, 3.772822690917868],
              [378, 3.026929455278914],
              [379, 3.8451028127222715],
              [380, 3.034081548804612],
              [381, 3.1424568293452433],
              [382, 2.948907523154243],
              [383, 3.3775303607879583],
              [384, 3.7657030182990914],
              [385, 3.2825442830040084],
              [386, 3.3498720103696757],
              [387, 2.8299644409835905],
              [388, 3.692475646404281],
              [389, 2.9599024501799835],
              [390, 3.4882146569544226],
              [391, 3.4499935301321267],
              [392, 3.108815816438273],
              [393, 3.6407012815431816],
              [394, 3.623538445437381],
              [395, 2.9009071229363714],
              [396, 2.7434833774689373],
              [397, 3.268166935374526],
              [398, 3.382652812269685],
              [399, 2.611467897060817],
              [400, 3.75794831643976],
              [401, 3.8593351402723988],
              [402, 2.973007080445764],
              [403, 3.0088751544384733],
              [404, 3.4936564241461365],
              [405, 3.775955325728645],
              [406, 3.842746267595146],
              [407, 3.513436595164431],
              [408, 3.8189676562454],
              [409, 3.4101854998760324],
              [410, 3.092655001656705],
              [411, 3.3987305374190484],
              [412, 3.1449708243561667],
              [413, 3.371208196069773],
              [414, 2.870449360341907],
              [415, 3.188739756147436],
              [416, 3.47335222365948],
              [417, 3.582837614113653],
              [418, 3.5441666321399596],
              [419, 3.0783684701356497],
              [420, 3.3760859120738145],
              [421, 3.000941417116387],
              [422, 2.9941952226239144],
              [423, 3.5163145483783476],
              [424, 3.6016347919357345],
              [425, 2.768147300896085],
              [426, 2.995784286997334],
              [427, 3.263555898040754],
              [428, 2.961614487498524],
              [429, 3.2537325648445634],
              [430, 2.901272545749292],
              [431, 3.2201606647912566],
              [432, 2.6783021944655125],
              [433, 3.219863085604164],
              [434, 2.613683577491408],
              [435, 2.8929119187479317],
              [436, 2.746582527392097],
              [437, 3.3876372086029463],
              [438, 2.8513348338240356],
              [439, 3.2019678478089917],
              [440, 2.9268085269449773],
              [441, 3.2337576212690666],
              [442, 2.6383352794295947],
              [443, 2.5387397537500185],
              [444, 3.2857288554143738],
              [445, 3.3570024976766026],
              [446, 2.9442958873449334],
              [447, 3.1959958043262997],
              [448, 2.714811415717948],
              [449, 2.9470016126542395],
              [450, 3.3138417988953144],
              [451, 2.487789127915552],
              [452, 3.0690779455917605],
              [453, 3.4597375521666143],
              [454, 2.8836137925157193],
              [455, 3.197002383335237],
              [456, 3.07702403038787],
              [457, 2.767976672747288],
              [458, 3.4324298520758054],
              [459, 2.7396797976535963],
              [460, 3.242851738372172],
              [461, 2.805064225002731],
              [462, 2.677325218494593],
              [463, 2.661579810702926],
              [464, 3.206025773904478],
              [465, 3.004260389571629],
              [466, 2.816120312442415],
              [467, 3.0252730888691746],
              [468, 3.219677385773225],
              [469, 2.689753334171829],
              [470, 3.253702025332794],
              [471, 2.7116350769401105],
              [472, 2.7012855922097767],
              [473, 3.2933275301381713],
              [474, 2.7938789988129358],
              [475, 2.9855478086590788],
              [476, 3.20214271706897],
              [477, 3.303903231386217],
              [478, 3.373285761829933],
              [479, 2.6434002334403646],
              [480, 2.5603293306435013],
              [481, 2.6732648278622073],
              [482, 2.8197827479133686],
              [483, 2.7197926984059864],
              [484, 2.4840339275435133],
              [485, 2.785856372433729],
              [486, 3.3143647390654944],
              [487, 2.5239824101345603],
              [488, 2.6475549814606043],
              [489, 3.1975695062690512],
              [490, 2.481183296073011],
              [491, 3.3272877982251154],
              [492, 2.8086475678687437],
              [493, 3.1392172600540755],
              [494, 2.729657018864235],
              [495, 2.616285675528034],
              [496, 3.0826843050488026],
              [497, 3.325925169795685],
              [498, 2.809365526193546],
              [499, 3.3793006300978012],
            ],
            lineWidth: 0.5,
          },
        ],
      },
    };
  },
}
</script>
