
<template>
  <b-row class="match-height">
    <b-col md="12">
        <highcharts-line-chart />
    </b-col>
    <b-col md="12">
    </b-col>    

    <!--
        <b-col md="6">
            <Highmaps :options="highmapsOptions"/>
        </b-col>
-->
  </b-row>
</template>




<script>
import { BRow, BCol, BLink } from "bootstrap-vue";

import HighchartsLineChart from './HighchartsLineChart.vue'


export default {
  components: {
    BRow,
    BCol,
    BLink,

    HighchartsLineChart,
    HighchartsStockChart

  },


};
</script>
